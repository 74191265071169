// eslint-disable-next-line import/prefer-default-export
export const restaurantHero = {
  title: "More than a restaurant POS system. An end-to-end restaurant solution",
  subtext:
    "Make your hard work more profitable with tools that work together. Restaurant POS software, online ordering, digital waitlist & reservations, labor management, and reporting.",
  heroImg: "video_thumb_home.png",
  imageClassName: "fixed-width",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "home-video.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const restaurantNav = [
  {
    url: "pos-terminal",
    itemName: "POS Terminal",
  },
  {
    url: "online-ordering",
    itemName: "Online Ordering",
  },
  {
    url: "spoton-reserve",
    itemName: "Reserve",
  },
  {
    url: "gift-cards",
    itemName: "E-gift Cards",
  },
  {
    url: "website-builder",
    itemName: "Website Builder",
  },
];

// Large Features Blocks, joined in one
// exported const to the frontend for mapping

export const posTerminalFeatures = {
  sectionTitle: "SpotOn Restaurant Point-of-Sale systems",
  sectionNavId: "pos-terminal",
  featureBlocks: [
    {
      blockTitle: "Software tailored just for you",
      blockSubTitle:
        "Get an end-to-end, personalized solution with all the features you need to maximize throughput, grow revenue, and improve your guest experience.",
      blockList: [
        "Point-of-sale",
        "Custom menu build",
        "Table layouts",
        "Labor management",
        "Multi-location management",
      ],
      blockImg: "pos-terminal-new.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Best-in-class reporting",
      blockSubTitle:
        "Get the most comprehensive data available to make smarter decisions without a costly third-party integration. Benefit from robust reporting on numerous aspects of your restaurant, including sales & labor by hour, product mix, employee sales, station sales, and transactions.",
      blockList: [
        "Over 90 fully-interactive reports",
        "Enterprise reporting",
        "Manager alerts and subscriptions",
        "Mobile reporting app to access data anywhere, anytime",
      ],
      blockImg: "rest-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle:
        "Restaurant POS hardware designed to run your entire operation",
      blockSubTitle:
        "Equip your restaurant with as many stations, handhelds, and kitchen peripherals as you need, with hardware that is robust and reliable, ready for the demands of a hard-working restaurant.",
      blockList: [
        "Touchscreen station",
        "SpotOn Serve handheld",
        "Customer-facing display",
        "Kitchen printer & KDS",
        "Food scale",
        "Backup router, so your POS system always works",
      ],
      blockImg: "rest-i.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Turn more tables with SpotOn Serve",
      id: "spoton-serve",
      blockSubTitle:
        "Equip your staff with our lightning fast handheld POS devices to improve throughput. Simply adding 3 handheld devices can lead to serving 5+ additional tables per day. With an average check of $40, that’s $70,000 in new annual revenue!",
      blockList: [
        "Send orders to the kitchen and bar in real-time",
        "Pay-at-table with EMV, Apple Pay, and Android Pay card reader",
        "Capture guest data with SpotOn Loyalty",
        "Built-in touchscreen display and thermal printer",
        "Expand your service range with Wi-Fi and 4G LTE connectivity",
      ],
      blockImg: "rest-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Automatically grow your marketing reach",
      id: "spoton-marketing-reach",
      blockSubTitle:
        "Collect guest contact information effortlessly and expand your reach with a point-of-sale system designed to grow your mailing list every touchpoint. Then use SpotOn’s built-in tools to drive repeat visits with marketing emails, limited-time deals, loyalty rewards, and more.",
      blockList: [
        "SpotOn Connect guest Wi-Fi with email capture",
        "Waitlist and reservation booking through SpotOn Reserve",
        "SpotOn Loyalty enrollment",
        "Online, mobile, and QR orders through SpotOn Order",
        "Enrollment deals through SpotOn Marketing",
      ],
      blockImg: "rest-h.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
  ],
};
export const onlineOrderingFeatures = {
  sectionTitle: "Online ordering",
  sectionNavId: "online-ordering",
  featureBlocks: [
    {
      blockTitle: "Grow sales & save big",
      blockSubTitle:
        "We’re here to help you adapt to changing guest needs. With online ordering from SpotOn, you’ll generate new sales, collect valuable guest data, and save big compared to third-party delivery apps. Guests can order directly from both your website and Google business profile—including Google Maps and Google Search results—and every order gets funneled straight to your POS, with 2-way guest texting capabilities, giving you control over everything while boosting efficiency.",
      blockImg: "res-main-sale.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Easy. Seamless. QR",
      blockSubTitle:
        "Turn tables faster while maximizing the staff you have with SpotOn QR. Guests simply can scan a QR code at the table to view the menu, then order and pay right from their phone—without ever touching a menu or credit card. ",
      blockImg: "rest-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Offer low-cost delivery",
      blockSubTitle:
        "Providing delivery service for your restaurant should be easy and affordable. With SpotOn Delivery, powered by DoorDash, you don’t need to hire and manage your own drivers. And with our flat rate delivery cost, you’ll save thousands compared to the commissions charged by third-party delivery apps.",
      blockImg: "olo-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
  ],
};

export const spotonReserveFeatures = {
  sectionNavId: "spoton-reserve",
  featureBlocks: [
    {
      blockTitle: "SpotOn Reserve",
      blockSubTitle:
        "As a restaurant owner, your main priority is to provide a safe and enjoyable experience that keeps guests coming back. SpotOn Reserve makes it easy by combining all the front-of-house tools you need into a single solution with optional point-of-sale integration.",
      blockList: [
        "Online reservations",
        "Reserve with Google",
        "Digital waitlisting",
        "Contact tracing",
        "Automated SMS confirmations",
        "Two-way table-ready messaging",
        "Guest text surveys",
      ],
      blockImg: "reserve.png",
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
  ],
};

export const unifiedExportLargeFeatures = [
  posTerminalFeatures,
  onlineOrderingFeatures,
  spotonReserveFeatures,
];

export const restuarantWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "White-glove service and support",
  content:
    "You have a lot on your plate, but with SpotOn, you’re no longer on your own. Work with our team or use our browser-based web portal to set up front-of-house policies, monitor operations and staffing assignments, and access customer analytics—all with a local, dedicated representative and 24/7 personalized support. We are here for you, no matter what.",
  contentList: [
    "Premium in-person hardware installation",
    "Face-to-face staff training from SpotOn specialists",
    "Ongoing virtual training for staff and management",
    "24/7 support from SpotOn employees included",
  ],
  ctaPrime: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
};

export const restuarantWebsite = {
  ctaInfoSecondary: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
  sectionNavId: "website-builder",
  featureBlocks: [
    {
      blockTitle: "Need a new website? We'll build it for you—fast",
      blockSubTitle:
        "Why pay thousands or waste valuable time building a site yourself when SpotOn can do it all for you?",
      blockList: [
        "Custom website build with your images and branding",
        "Online ordering with POS integration",
        "Quick turnaround time—in as little as 48 hours!",
        "Search engine optimization (SEO) to help customers find you online",
        "Hosting, domain name, self-service dashboard, and lifetime support included",
      ],
      blockImg: "laptop-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Reach more hungry guests with Google",
      blockSubTitle:
        "SpotOn's POS for restaurants makes it easier than ever for you to take advantage of Google features. Allow your clients to find you faster and order from your restaurant’s Google my Business profile.",
      blockList: [
        {
          title: "Google my Business",
          subtext:
            "Claim your profile with SpotOn in minutes, not weeks! Easily take control of your online presence and make it easy for customers to find your address, website, hours, and phone number.",
        },
        {
          title: "Order with Google",
          subtext:
            "Guests search for what they’re craving, find your restaurant, and order directly from your Business profile with ease. No apps. No logins. Easy.",
        },
      ],
      blockImg: "order-with-google.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
  ],
};

export const restaurantVideo = {
  title:
    "Run & grow your business with SpotOn's restaurant point-of-sales systems",
  videoBtn: "Watch video",
  posterImg: "run-and-grow-video-poster.png",
  posterWrapperClass: "",
  videoUrlId: "EbacP7r-Mx0",
};

export const restaurantCta = {
  title: "Book your consultation.",
  subtext:
    "Learn about everything that you may need working with different SpotOn products.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
};

export const restaurantFAQs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "Visit our Help Center",
    ctaTarget: "https://help.spoton.com/",
  },
  list: [
    {
      title: "How much does a restaurant POS cost?",
      body: `Restaurant POS systems typically range in pricing from $60 - $300 per month for software (per station) and from $700 - $1,700 for the POS hardware. Additional services, such as integrated online ordering and digital loyalty programs, usually cost extra. SpotOn offers affordable pricing options and custom configurations that are built to fit your specific needs and budget. <a href="https://www.spoton.com/restaurant-pos/demo" target="_blank" >Contact our team </a> to learn more and get a personalized quote."`,
    },
    {
      title: "Which POS system should I choose for my restaurant?",
      body: `The type of POS system you will want to choose depends on your restaurant concept. For example, counter-service concepts generally benefit from 1 or more countertop POS stations with customer-facing displays, KDS, and perhaps a handheld POS device for line-busting. Meanwhile, full-service restaurants typically can benefit from 1 or more POS stations and multiple handhelds to speed up table turn times. SpotOn offers flexible technology to fit these scenarios and more, with integrated online ordering and  <a "https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="_blank" >QR ordering</a>, and more.`,
    },
    {
      title: "Which businesses can use a restaurant POS system?",
      body: `All restaurants can benefit from using a restaurant POS system. SpotOn Restaurant is designed to work across several restaurant categories, including <a href="https://spoton.com/blog/restaurant-profit-margins-casual-dining/" target="_blank" >casual dining </a>, <a href="https://www.spoton.com/fine-dining-pos/" target="_blank" >fine dining</a>, <a href="https://spoton.com/blog/restaurant-profit-margins-fast-casual/" target="_blank" >fast casual</a>, <a href="https://spoton.com/blog/restaurant-profit-margins-quick-service/" target="_blank" >quick service</a>, <a href="https://www.spoton.com/nightclub-and-bar-pos/" target="_blank" >bars & nightclubs</a>, <a href="https://www.spoton.com/food-truck-pos/" target="_blank" >food trucks</a>, and pizzarias. Because of its customizability, SpotOn components can be mixed and matched according to your restaurant's specific needs.`,
    },
    {
      title: "What are the most popular restaurant POS features?",
      body: `There are many popular features, including restaurant POS hardware for full-service and counter-service concepts, customizable restaurant POS software, <a href="/products/reporting/" target="_blank">reporting</a>, <a href="/products/online-order-and-delivery/" target="_blank">integrated online ordering</a>, <a href="/products/online-order-and-delivery/" target="_blank">QR ordering</a>, <a href="/products/reserve/" target="_blank">digital waitlist and reservations</a>, and <a href="/products/labor-management/" target="_blank">labor management.</a>`,
    },
    {
      title:
        "Does the POS system continue to function if you lose internet connection?",
      body: `Yes, SpotOn Restaurant POS systems for restaurants have a backup router that wirelessly connects your system to the internet even if the primary service from your internet service provider goes down.`,
    },
    {
      title: "Can the restaurant POS system link to my other software tools?",
      body: `Yes, SpotOn Restaurant has a growing list of third party software integrations, including for inventory, accounting, payroll, labor management, online ordering, delivery, and more.`,
    },
    {
      title: "How do I set up online ordering for my restaurant?",
      body: `There are many options for <a href="https://www.spoton.com/blog/create-online-ordering-for-restaurants/" target="_blank">how to create online ordering for restaurants.</a> SpotOn offers a commission-free first-party <a href="/products/online-order-and-delivery/" target="_blank">restaurant online ordering system</a> that sends orders placed on your website and Google directly to your restaurant POS.`,
    },
    {
      title: "What is a restaurant POS system?",
      body: `A restaurant POS system is a device that makes it faster and easier to place food and drink orders, send them to the back of the house, and then take payments from guests. Visit here to learn <a href="https://www.spoton.com/blog/why-you-need-restaurant-pos-system/" target="_blank">why you need a POS system for your restaurant.</a>`,
    },
  ],
};

export const restaurantSubverticals = {
  title: "Who we serve",
  subverticalInfo: [
    {
      target: "/fine-dining-pos",
      iconImg: "fine-dining.png",
      subverticalName: "Fine dining",
      subverticalContent:
        "Offer your guests a seamless and refined dining experience with a custom end-to-end point-of-sale solution that is tailor-made to streamline operations.",
    },
    {
      target: "/quick-service-pos",
      iconImg: "qsr.png",
      subverticalName: "Quick-service",
      subverticalContent:
        "Keep the line moving fast and stand out from your competitors with integrated digital marketing and loyalty at checkout.",
    },
    {
      target: "/casual-dining-pos",
      iconImg: "full-service.png",
      subverticalName: "Casual full-service",
      subverticalContent:
        "Let your customers order and pay their way with a flexible configuration that makes sense for your restaurant.",
    },
  ],
};

export const restaurantTestimonials = {
  title: "Why SpotOn's restaurant POS system",
  punctuation: "?",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};
export const demoTestimonials = {
  title: "",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration the entire Process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};
export const restaurantGiftcardsTestimonials = {
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};
export const restaurantsIntegrations = {
  title: "Designed to integrate with the software you love",
  subtext:
    "We don’t make you work around our technology. Our flexible restaurant POS software is designed to integrate with other systems so it works for you—not the other way around.",
};

export const restaurantArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles",
};
export const LandingPageTestimonials = {
  title: "What other restaurant owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration the entire Process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const advisoryBoard = {
  title: "Meet our Restaurant Advisory Council",
  subtext:
    "This elite group of restaurant owners, investors, and entrepreneurs provides real-world expertise and insights to ensure SpotOn's solutions are built to help you maximize profits.",
  SecondaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/company/advisory-board",
  },
  fatText: "Advisory Council",
  image: "advisory_board.png",
  alt: "restaurant Pos",
  noBgShapes: true,
  reverseOrder: true,
  assetStyles: { maxWidth: 600, margin: "0px auto 40px" },
};

export const integrationV2 = {
  title: "Designed to integrate with the software you love",
  subTitle:
    "We don’t make you work around our technology. Our POS systems for restaurants are designed to integrate with other software and systems so it works for you—not the other way around.",
  partnerTitle: "Become an integration partner of SpotOn",
  partnerSubTitle:
    "SpotOn's Integration Partner Program enables partners to scale their integrations while providing our mutual customers more robust solutions to grow their business!",
  partnerBackgroundImageName: "",
  partnerBackgroundMobileImageName: "",
  partnerCta: {
    title: "Request an integration",
    target: "/partners/become-a-partner",
  },
};
